import React from "react";
import Layout from "../components/layout";

import "../styles/w3.css";

const AboutPage = () => (
	<Layout title="About">
		<div className={"centered w3-margin-top index w3-padding-large"}>
			<h1 className={"w3-jumbo w3-animate-top w3-center"}>COMING SOON</h1>
		</div>
	</Layout>
);

export default AboutPage;
